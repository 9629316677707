export const footerOptsLeft = [
  {
    title: "IT Services",
    childOpts: [
      { name: "IT Consulting Services", path: "/" },
      { name: "Tech Support Services", path: "/" },
    ],
  },
  {
    title: "Cloud Services",
    childOpts: [
      { name: "Cloud Computing Solutions", path: "/" }
    ],
  },
];

export const footerOptsRight = [
  {
    title: "Contact Info",
    childOpts: [
      { name: "Phone: (+84) 901 946 329", path: "/" },
      { name: "Email: contact@napaglobal.com", path: "/" },
      { name: "We're a remote company serving businesses worldwide.", path: "/"}
    ],
  }
];

export const headerNavigations = [
  {
    name: "Home",
    path: "/",
    id: 'home-section'
  },
  {
    name: "Services",
    path: "/",
    id: 'services-section'
  },
  {
    name: "Projects",
    path: "/",
    id: 'projects-section'
  },
  {
    name: "Profile",
    path: "/",
    id: 'profile-section'
  },
  {
    name: "Contact",
    path: "/",
    id: 'contact-section'
  },
  // {
  //   name: "News",
  //   path: "/news",
  // },
  
];
