import {
    createAvatar,
    createMessage,
    createCountryName,
    createClientTitle,
    eventHiddenPage,
} from "./createdElement";

export default function animateBanner2(data, animationBetween = 7000){
    
    const chart = document.getElementById('chartdiv');

    //remove title
    chart.parentNode.parentNode?.childNodes.forEach((e) => {
      if (/wrapper/.test(e.className)) {
        e.style.display = 'none';
      }
    });

    //country map animate
    const animateCountry = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'animate'
    );
    animateCountry.setAttributeNS(null, 'dur', '1000ms');
    animateCountry.setAttributeNS(null, 'attributeName', 'fill');
    animateCountry.setAttributeNS(null, 'values', '#7c67bf;#7c67bf');
    animateCountry.setAttributeNS(null, 'fill', 'freeze');
    animateCountry.setAttributeNS(null, 'begin', 'indefinite');
    
    function animateCountryEnd(time=1000){
        const animateCountryEnd = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'animate'
        );
        animateCountryEnd.setAttributeNS(null, 'dur', time+'ms');
        animateCountryEnd.setAttributeNS(null, 'attributeName', 'fill');
        animateCountryEnd.setAttributeNS(null, 'values', '#7c67bf;#7561c1');
        animateCountryEnd.setAttributeNS(null, 'fill', 'freeze');
        animateCountryEnd.setAttributeNS(null, 'begin', 'indefinite');

        return animateCountry
    }

    const groups = chart.querySelectorAll("[role='group']");
      
    const groupStatus = groups[3]?.children[0];
    
    // Map country polygons list
    const groupCountries = groups[2]?.children[0];
    
    const {path: path1, text: text1} = createClientTitle(
        'M0 7 L-20 85 L-210 85',
        '-196', '59',
        '-210', '77',
        data[0].clientName,
        data[0].position,
    )
    const g1 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'g'
    );
    g1.appendChild(path1)
    g1.appendChild(text1)
    const message1 = createMessage(-120, 110, data[0].message, 40, 23)
    g1.appendChild(message1)
    g1.style.visibility = 'hidden'

    const {path: path2, text: text2} = createClientTitle(
        'M0 7 L-20 73 L-200 73',
        '-175', '47',
        '-145', '65',
        data[1].clientName,
        data[1].position,
    )
    const g2 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'g'
    );
    g2.appendChild(path2)
    g2.appendChild(text2)
    const message2 = createMessage(-120, 100, data[1].message, 40, 23)
    g2.appendChild(message2)
    g2.style.visibility = 'hidden'

    const {path: path3, text: text3} = createClientTitle(
        'M0 7 L-20 143 L-200 143',
        '-150', '117',
        '-158', '135',
        data[2].clientName,
        data[2].position,
    )
    const g3 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'g'
    );
    g3.appendChild(path3)
    g3.appendChild(text3)
    const message3 = createMessage(-120, 170, data[2].message, 40, 23)
    g3.appendChild(message3)
    g3.style.visibility = 'hidden'

    function animate1(){
        groupCountries?.childNodes[3]?.appendChild(animateCountry);
        animateCountry.beginElement();
        
        g1.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 1000,
            fill: 'forwards',
        });
    }

    function animate2(){
        groupCountries?.childNodes[24]?.appendChild(animateCountry);
        animateCountry.beginElement();
        
        g2.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 1000,
            fill: 'forwards',
        });
    }
    
    function animate3(){
        groupCountries?.childNodes[69]?.appendChild(animateCountry);
        animateCountry.beginElement();
        
        g3.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 1000,
            fill: 'forwards',
        });
    }
    
    function animate1End(time=1000){
        groupCountries?.childNodes[3]?.appendChild(animateCountryEnd(time));
        // animateCountryEnd.beginElement();
        
        g1.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: time,
            fill: 'forwards',
        });
    }

    function animate2End(time=1000){
        groupCountries?.childNodes[24]?.appendChild(animateCountryEnd(time));
        // animateCountryEnd.beginElement();
        
        g2.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: time,
            fill: 'forwards',
        });
    }
    
    function animate3End(time=1000){
        groupCountries?.childNodes[69]?.appendChild(animateCountryEnd(time));
        // animateCountryEnd.beginElement();
        
        g3.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: time,
            fill: 'forwards',
        });
    }

    const animateArray = [ animate1, animate2, animate3, animate1];
    const animateEndArray = [ animate1End, animate2End, animate3End, animate1End];
    const gs = [g1, g2, g3, g1]
    const timeInfo = {
        // isStop: false,
        startOut: 0,
        lastAnimateStart: 0,
        direction: 1,
        n: 2,
        planePause: 0,
        length: animationBetween,
        nextAnimate: 0
    }

    function animateN(nextAnimate, n=2, direction=1, endAnimateTime=-1, planePause=0){
        if(timeInfo.nextAnimate!==nextAnimate)
            return
    
        const lastAnimate = n-direction
        
        let wait = 1000
        if(endAnimateTime>-1)
            wait = 500 + endAnimateTime
            
        animateEndArray[lastAnimate](wait)
        setTimeout(() => {
            gs[lastAnimate].style.visibility = 'hidden'
            gs[n].style.visibility = 'visible'
            animateArray[n]();
        }, wait);

        // let planePause = 0;
        let reDirection = 1;
        if(n==0 || n==3){
            reDirection = -1;
            planePause = 1000;
        }
        direction = reDirection*direction;

        timeInfo.lastAnimateStart = new Date().getTime();
        timeInfo.n = n+direction
        timeInfo.direction = direction
        timeInfo.planePause = planePause
        timeInfo.length = animationBetween

        timeInfo.nextAnimate += 1
        const next = timeInfo.nextAnimate
        setTimeout(() => {
            animateN(next, n+direction, direction, -1, 0)
        }, animationBetween + planePause);
    }

    function pageOUt(){
        timeInfo.startOut = new Date().getTime()
        timeInfo.nextAnimate += 1
    }
    
    function pageIn(){
        let wait = 0
        let now = new Date().getTime()
        let length = now-timeInfo.lastAnimateStart
        let endAnimateTime = 0
        let distance = length-timeInfo.length
        if(distance>=0 && distance <500){
            endAnimateTime = distance
        } else if(distance>=500){
            endAnimateTime = 0
        } else {
            wait = timeInfo.length-length
            endAnimateTime = -1
        }

        timeInfo.nextAnimate += 1
        const next = timeInfo.nextAnimate
        const n = timeInfo.n
        const direction = timeInfo.direction
        const planePause = timeInfo.planePause
        setTimeout(() => {
            animateN(next, n, direction, endAnimateTime)
        }, wait + planePause);
    }

    setTimeout(() => {
        chart.childNodes[0].style.overflow = "visible";

        groupStatus?.childNodes[2].appendChild(g1);
        groupStatus?.childNodes[2].appendChild(createCountryName(45, -7, data[0].title, 23));
        groupStatus?.childNodes[2].appendChild(createAvatar(data[0].imageURL),1);
        
        groupStatus?.childNodes[3].appendChild(g2);
        groupStatus?.childNodes[3].appendChild(createCountryName(40, 0, data[1].title, 23));
        groupStatus?.childNodes[3].appendChild(createAvatar(data[1].imageURL),2);
        
        groupStatus?.childNodes[4].appendChild(g3);
        groupStatus?.childNodes[4].appendChild(createCountryName(48, -7, data[2].title, 23));
        groupStatus?.childNodes[4].appendChild(createAvatar(data[2].imageURL),3);

        g2.style.visibility = 'visible'
        timeInfo.length = animationBetween - 500
        timeInfo.lastAnimateStart = new Date().getTime()
        animate2();

        timeInfo.nextAnimate += 1
        const next = timeInfo.nextAnimate
        setTimeout(() => {
          animateN(next);
        }, animationBetween - 500);

    }, 1000);

    //remove default small chart
    const defaultChart = chart.childNodes[0].childNodes[0].childNodes[1].childNodes[1].childNodes[1];
        defaultChart.parentNode.removeChild(defaultChart);

    eventHiddenPage(pageOUt, pageIn)
        
}