import {
    createAvatar,
    createCountryName,
} from "./createdElement";

function createMessage(message, name, position){
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.width = '90vw';
    div.style.textAlign= 'center'
    div.style.opacity = 0;
    div.style.fontSize = '13px';
    div.innerHTML = message;
    const client = document.createElement('div');
    client.style.width = '100%';
    client.style.textAlign= 'center'
    client.style.fontSize = '16px';
    client.style.marginTop = '10px';
    client.innerHTML = name+', '+position;

    div.appendChild(client)

    return div
}

export default function mobileAnimateBanner2(data, animationBetween = 7000){
    
    const chart = document.getElementById('chartdiv');

    //remove title
    chart.parentNode.parentNode?.childNodes.forEach((e) => {
      if (/wrapper/.test(e.className)) {
        e.style.display = 'none';
      }
    });

    //country map animate
    const animateCountry = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'animate'
    );
    animateCountry.setAttributeNS(null, 'dur', '1000ms');
    animateCountry.setAttributeNS(null, 'attributeName', 'fill');
    animateCountry.setAttributeNS(null, 'values', '#7c67bf;#7c67bf');
    animateCountry.setAttributeNS(null, 'fill', 'freeze');
    animateCountry.setAttributeNS(null, 'begin', 'indefinite');
    
    const animateCountryEnd = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'animate'
    );
    animateCountryEnd.setAttributeNS(null, 'dur', '1000ms');
    animateCountryEnd.setAttributeNS(null, 'attributeName', 'fill');
    animateCountryEnd.setAttributeNS(null, 'values', '#7c67bf;#7561c1');
    animateCountryEnd.setAttributeNS(null, 'fill', 'freeze');
    animateCountryEnd.setAttributeNS(null, 'begin', 'indefinite');

    const groups = chart.querySelectorAll("[role='group']");
      
    const groupStatus = groups[3]?.children[0];
    
    // Map country polygons list
    const groupCountries = groups[2]?.children[0];
    
    const message1 = createMessage(data[0].message, data[0].clientName, data[0].position);
    const message2 = createMessage(data[1].message, data[1].clientName, data[1].position);
    const message3 = createMessage(data[2].message, data[2].clientName, data[2].position);
    
    chart.appendChild(message1);
    chart.appendChild(message2);
    chart.appendChild(message3);

    const avatar1 = createAvatar(data[0].imageURL,1)
    const countryName1 = createCountryName(45, -10, data[0].title, 23)
    avatar1.appendChild(countryName1)
    avatar1.style.visibility = 'hidden'

    const avatar2 = createAvatar(data[1].imageURL,2)
    const countryName2 = createCountryName(-40, 0, data[1].title, 23)
    avatar2.appendChild(countryName2)
    avatar2.style.visibility = 'hidden'

    const avatar3 = createAvatar(data[2].imageURL,3)
    const countryName3 = createCountryName(48, -7, data[2].title, 23)
    avatar3.appendChild(countryName3)
    avatar3.style.visibility = 'hidden'

    function setPosition(mapBottom){
        message1.style.top = mapBottom+10+'px';
        message2.style.top = mapBottom+10+'px';
        message3.style.top = mapBottom+10+'px';
    }

    function animate1(){
        groupCountries?.childNodes[3]?.appendChild(animateCountry);
        animateCountry.beginElement();

        avatar1.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 1000,
            fill: 'forwards',
        });
        
        message1.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 1000,
            fill: 'forwards',
        });
    }

    function animate2(){
        groupCountries?.childNodes[24]?.appendChild(animateCountry);
        animateCountry.beginElement();

        avatar2.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 1000,
            fill: 'forwards',
        });
        
        message2.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 1000,
            fill: 'forwards',
        });
    }
    
    function animate3(){
        groupCountries?.childNodes[69]?.appendChild(animateCountry);
        animateCountry.beginElement();

        avatar3.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 1000,
            fill: 'forwards',
        });
        
        message3.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: 1000,
            fill: 'forwards',
        });
    }
    
    function animate1End(){
        groupCountries?.childNodes[3]?.appendChild(animateCountryEnd);
        animateCountryEnd.beginElement();

        avatar1.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: 1000,
            fill: 'forwards',
        });
        
        message1.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: 1000,
            fill: 'forwards',
        });
    }

    function animate2End(){
        groupCountries?.childNodes[24]?.appendChild(animateCountryEnd);
        animateCountryEnd.beginElement();

        avatar2.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: 1000,
            fill: 'forwards',
        });
        
        message2.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: 1000,
            fill: 'forwards',
        });
    }

    function animate3End(){
        groupCountries?.childNodes[69]?.appendChild(animateCountryEnd);
        animateCountryEnd.beginElement();

        avatar3.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: 1000,
            fill: 'forwards',
        });
        
        message3.animate([{ opacity: 1 }, { opacity: 0 }], {
            duration: 1000,
            fill: 'forwards',
        });
    }

    const animateArray = [ animate1, animate2, animate3, animate1];
    const animateEndArray = [ animate1End, animate2End, animate3End, animate1End];
    const avatars = [avatar1, avatar2, avatar3, avatar1]
    
    function animateN(n=2, direction=1){
        const lastAnimate = n-direction
        animateEndArray[lastAnimate]();
        setTimeout(() => {
            avatars[lastAnimate].style.visibility = 'hidden'
            avatars[n].style.visibility = 'visible'
            animateArray[n]();
        }, 1000);

        let planePause = 0;
        let reDirection = 1;
        if(n==0 || n==3){
            reDirection = -1;
            planePause = 1000;
        }
        direction = reDirection*direction;

        setTimeout(() => {
            animateN(n+direction, direction)
        }, animationBetween + planePause);
    }

    setTimeout(() => {
        groupStatus?.childNodes[3]?.appendChild(avatar2)
        groupStatus?.childNodes[2]?.appendChild(avatar1)
        groupStatus?.childNodes[4]?.appendChild(avatar3)
        
        chart.childNodes[0].style.overflow = "visible";

        setPosition(groupCountries?.childNodes[11]?.getBoundingClientRect().bottom);

        avatar2.style.visibility = 'visible'
        animate2();
        
        setTimeout(() => {
          animateN();
        }, animationBetween - 500);
    }, 1000);
    
    //remove default small chart
    const defaultChart = chart.childNodes[0].childNodes[0].childNodes[1].childNodes[1].childNodes[1];
        defaultChart.parentNode.removeChild(defaultChart);

}