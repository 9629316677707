export function createClientTitle(pD, nameX, nameY, positionX, positionY, nameValue, positionValue){
    const path = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    );
    path.setAttributeNS(null, 'd', pD);
    path.setAttributeNS(null, 'fill', 'none');
    path.setAttributeNS(null, 'stroke', '#FFF');
    path.setAttributeNS(null, 'stroke-width', '2');

    const text = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text'
    );
    text.setAttributeNS(null, 'stroke-width', '0');
    text.setAttributeNS(null, 'fill', '#FFF');

    
    const name = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'tspan'
    );
    name.setAttributeNS(null, 'x', nameX);
    name.setAttributeNS(null, 'y', nameY);
    name.innerHTML = nameValue;
    name.style.fontSize = '20px';
    name.style.fontWeight = 'bold';

    const position = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'tspan'
    );
    position.setAttributeNS(null, 'x', positionX);
    position.setAttributeNS(null, 'y', positionY);
    position.innerHTML = positionValue;
    position.style.fontSize = '16px';

    text.appendChild(name)
    text.appendChild(position)

    return {path, text}
}

export function createMessage(x, y, message, characters, lineSpace){
    const messageArray = []
    let end = 0
    let start = 0
    for(let i=0; i<message.length; i++){
        if((end-start)>characters){
            if(message[i]==' '){
                messageArray.push(message.slice(start, end))
                start = end+1
            }
        }
        end += 1
        if(end == message.length)
            messageArray.push(message.slice(start, end))
    }

    const text = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text'
    );
    text.setAttributeNS(null, 'stroke-width', '0');
    text.setAttributeNS(null, 'fill', '#FFF');

    messageArray.forEach((message,i)=>{
        const row = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'tspan'
        );
        row.setAttributeNS(null, 'x', x+'');
        row.setAttributeNS(null, 'y', y + lineSpace*i + '');
        row.setAttributeNS(null, 'dominant-baseline', "middle");
        row.setAttributeNS(null, 'text-anchor', "middle");
        row.innerHTML = message;
        row.style.fontSize = '20px';

        text.appendChild(row)
    })

    return text
}

export function createCountryName(x, y, countryName, lineSpace){
    const words = countryName.split(' ')

    const text = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'text'
    );
    text.setAttributeNS(null, 'stroke-width', '0');
    text.setAttributeNS(null, 'fill', '#FFF');

    words.forEach((word,i)=>{
        const row = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'tspan'
        );
        row.setAttributeNS(null, 'x', x+'');
        row.setAttributeNS(null, 'y', y + lineSpace*i + '');
        row.setAttributeNS(null, 'dominant-baseline', "middle");
        row.setAttributeNS(null, 'text-anchor', "middle");
        row.innerHTML = word;
        row.style.fontSize = '20px';

        text.appendChild(row)
    })

    return text
}

export function createAvatar(href, id){
    const width = 60;
    const height = 60;
    const x = -width/2;
    const y = -80 - height;
    const cx = -0;
    const cy = -110;
    const r = 30;

    const image = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'image'
    );
    // image.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', "https://res.cloudinary.com/dh8l9y2c2/image/upload/v1631500634/NapaImage/613eb959e419b221e64ae215.png");
    image.setAttributeNS(null, 'href', href);
    image.setAttributeNS(null, 'x', x+'');
    image.setAttributeNS(null, 'y', y+'');
    image.setAttributeNS(null, 'width', width+'');
    image.setAttributeNS(null, 'height', height+'');
    image.setAttributeNS(null, 'clip-path', 'url(#circle'+id+')');

    
    const clipPath = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'clipPath'
    );
    clipPath.setAttributeNS(null, 'id', 'circle'+id);
    const circle = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle'
    );
    circle.setAttributeNS(null, 'cx', cx+'');
    circle.setAttributeNS(null, 'cy', cy+'');
    circle.setAttributeNS(null, 'r', r+'');
    circle.setAttributeNS(null, 'stroke', 'black');
    circle.setAttributeNS(null, 'stroke-width', '3');
    
    const circle1 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle'
    );
    circle1.setAttributeNS(null, 'cx', cx+'');
    circle1.setAttributeNS(null, 'cy', cy+'');
    circle1.setAttributeNS(null, 'r', r+'');
    circle1.setAttributeNS(null, 'stroke', 'white');
    circle1.setAttributeNS(null, 'stroke-width', '3');
    circle1.setAttributeNS(null, 'fill', 'none');

    const polygon = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'polygon'
    );
    polygon.setAttributeNS(null, 'points', '0,-10 '+'-2,'+cy+ ' 2,'+cy);
    // polygon.setAttributeNS(null, 'stroke', 'white');
    // polygon.setAttributeNS(null, 'stroke-width', '3');
    polygon.setAttributeNS(null, 'fill', 'white');
    
    const g = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'g'
    );

    clipPath.appendChild(circle)
    g.appendChild(polygon);
    g.appendChild(image);
    g.appendChild(clipPath);
    g.appendChild(circle1);

    return g
}

export function eventHiddenPage(pageOUt, pageIn){
    var hidden = "hidden";

  // Standards:
  if (hidden in document)
    document.addEventListener("visibilitychange", onchange);
  else if ((hidden = "mozHidden") in document)
    document.addEventListener("mozvisibilitychange", onchange);
  else if ((hidden = "webkitHidden") in document)
    document.addEventListener("webkitvisibilitychange", onchange);
  else if ((hidden = "msHidden") in document)
    document.addEventListener("msvisibilitychange", onchange);
  // IE 9 and lower:
  else if ("onfocusin" in document)
    document.onfocusin = document.onfocusout = onchange;
  // All others:
  else
    window.onpageshow = window.onpagehide
    = window.onfocus = window.onblur = onchange;

  function onchange (evt) {
    var v = "visible", h = "hidden",
        evtMap = {
          focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h
        };

    evt = evt || window.event;
    if (evt.type in evtMap)
      if(evtMap[evt.type]=='visible'){
          pageIn()
      } else {
          pageOUt()
      }
    else
        if (document[hidden]) {
            pageOUt()
        } else {
            pageIn()
        }
  
  }

}