import { useEffect } from 'react';
import animateBanner2 from './animate';
import mobileAnimateBanner2 from './mobileAnimate';

export default function Map(props) {
  const fixData = [
    {
      latitude: 40.00932598320569,
      longitude: -101.88069203914904,
      title: 'United States',
      length: 90,
      imageURL:
        'https://res.cloudinary.com/dh8l9y2c2/image/upload/v1631500634/NapaImage/613eb959e419b221e64ae215.png',
      message:
        '"The NAPA Global testing team has done a great job helping us with software testing and we just confirmed NAPA’s second contract renewal for additional work."',
      clientName: 'Jenifer M. Gamiao',
      position: 'Principal Consultant - ITK',
      },
    {
      latitude: 36.55005325234149,
      longitude: 138.81495187910292,
      title: 'Japan',
      length: 110,
      imageURL:
        'https://res.cloudinary.com/dh8l9y2c2/image/upload/v1630378076/NapaImage/612d985be419b221e64ae1a3.png',
      message:
        '"After working with 6 or 7 companies on a trial project, I chose NAPA Global – to be my partner. I am really happy with the quality of work produced by the engineering team of the company"',
      clientName: 'Taisuke Yokoi',
      position: 'CEO LisB',
      },
    {
      latitude: 54.166724956307085,
      longitude: -1.7463790675075792,
      title: 'United Kingdom',
      length: 40,
      imageURL:
        'https://res.cloudinary.com/dh8l9y2c2/image/upload/v1630377989/NapaImage/612d9804e419b221e64ae1a2.png',
      message:
        `"I'm already convinced the firm is of high quality, and I'd strongly recommend them to anyone who is looking to build high-impact software solutions."`,
      clientName: 'Tien Do',
      position: 'CEO Dualify',
      }
  ];
  useEffect(() => {
    am4core.ready(function () {
      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create map instance
      var chart = am4core.create('chartdiv', am4maps.MapChart);
      chart.geodata = am4geodata_worldLow;
      chart.projection = new am4maps.projections.Miller();
      chart.homeZoomLevel = 1;
      chart.nonScaling = false;
      chart.homeZoomLevel = false;
      // chart.scale = .8

      // Create map polygon series
      var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;
      polygonSeries.verticalCenter = 'middle';
      polygonSeries.mapPolygons.template.fill = chart.colors
        .getIndex(0)
        .lighten(0.1);
      polygonSeries.mapPolygons.template.nonScalingStroke = true;
      polygonSeries.exclude = ['AQ'];

      // Configure series
      var polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = '{name}';
      polygonTemplate.fill = am4core.color('#533db2'); //74B266 61168a
      polygonTemplate.stroke = '#493091';
      polygonTemplate.strokeWidth = .5;

      // var color1 = chart.colors.getIndex(0);
      var color1 = '#FFF';
      // Add line bullets
      var cities = chart.series.push(new am4maps.MapImageSeries());

      var imageTemplate = cities.mapImages.template;

      imageTemplate.propertyFields.longitude = 'longitude';
      imageTemplate.propertyFields.latitude = 'latitude';
      imageTemplate.propertyFields.scale =.7

      // var pin = imageTemplate.createChild(am4plugins_bullets.PinBullet);

      // Configuring pin appearance
      // pin.background.fill = color1;
      // pin.background.pointerBaseWidth = 3;
      // pin.background.pointerLength = 250;
      // pin.background.propertyFields.pointerLength = 'length';
      // pin.circle.fill = 'red';

      // pin.image = new am4core.Image();
      // pin.image.propertyFields.href = 'imageURL';

      // pin.label = new am4core.Label();
      // pin.label.text = '{message}';
      // if (document.documentElement.clientWidth < 768) {
      //   pin.label.scale = 0.01;
      // } else pin.label.scale = 1.1;
      // pin.label.fill = '#FFF';
      // // pin.label.background.fill = "#FFF";
      // pin.label.adapter.add('dy', function (dy) {
      //   return 270;
      // });
      // pin.label.adapter.add('dx', function (dy) {
      //   return -110;
      // });
      // pin.label.wrap = true;
      // pin.label.maxWidth = 400;
      // // pin.label.fontWeight = 'bold';
      // // pin.propertyFields.dx = 100;

      // var label = pin.createChild(am4core.Label);
      // label.wrap = true;
      // label.width = 100;
      // label.text = '{title}';
      // // label.fontWeight = 'bold';
      // label.propertyFields.dy = 'value';
      // label.propertyFields.shape = 'bullet';
      // label.verticalCenter = 'middle';
      // label.fill = color1;
      // if (document.documentElement.clientWidth < 768) {
      //   label.adapter.add('dy', function (dy) {
      //     return 30;
      //   });
      //   label.adapter.add('dx', function (dy) {
      //     return -24;
      //   });
        
      // } else {
      //   label.adapter.add('dy', function (dy) {
      //     return -17;
      //   });
      //   label.adapter.add('dx', function (dy) {
      //     return 10;
      //   });
      // }

      cities.data = fixData;

      cities.mapImages.template.nonScaling = true;

      var city = cities.mapImages.template.createChild(am4core.Circle);
      city.radius = 7;
      // city.fill = "red"; //chart.colors.getIndex(0).brighten(-0.2);
      city.fill = '#f00605';
      city.strokeWidth = 2;
      city.stroke = am4core.color('#fff');

      function addCity(coords, title) {
        var city = cities.mapImages.create();
        city.latitude = coords.latitude;
        city.longitude = coords.longitude;
        city.tooltipText = title;
        return city;
      }

      // Add lines
      var lineSeries = chart.series.push(new am4maps.MapArcSeries());
      lineSeries.mapLines.template.line.strokeWidth = 0.5;
      lineSeries.mapLines.template.line.strokeOpacity = 0.9;

      lineSeries.mapLines.template.line.stroke = '#FFF'; //city.fill;
      lineSeries.mapLines.template.line.nonScalingStroke = true;
      lineSeries.mapLines.template.line.strokeDasharray = '3,3';
      lineSeries.zIndex = 100;

      var shadowLineSeries = chart.series.push(new am4maps.MapLineSeries());
      shadowLineSeries.mapLines.template.line.strokeOpacity = 0;
      shadowLineSeries.mapLines.template.line.scale = 1.2;
      shadowLineSeries.mapLines.template.line.nonScalingStroke = true;
      shadowLineSeries.mapLines.template.shortestDistance = false;
      shadowLineSeries.zIndex = 5;
      shadowLineSeries.mapLines.template.color = '#fff';

      function addLine(from, to) {
        var line = lineSeries.mapLines.create();
        line.imagesToConnect = [from, to];
        line.line.controlPointDistance = -0.3;

        var shadowLine = shadowLineSeries.mapLines.create();
        shadowLine.imagesToConnect = [from, to];

        return line;
      }
      const route = cities.data.map((item, ind) => {
        return addCity(
          {
            latitude: item.latitude,
            longitude: item.longitude
          },
          item.title
        );
      });

      // );
      for (let i = 0; i < route.length; i++) {
        if (i == route.length - 1) {
          addLine(route[i], route[0]);
          break;
        }
        addLine(route[i], route[i + 1]);
      }

      // Add plane
      var plane = lineSeries.mapLines.getIndex(0).lineObjects.create();
      plane.position = 0;
      plane.width = 100;
      plane.height = 100;

      plane.adapter.add('scale', function (scale, target) {
        return 1.2 * (1 - Math.abs(0.5 - target.position));
      });

      var planeImage = plane.createChild(am4core.Sprite);
      planeImage.scale = 0.08;
      planeImage.horizontalCenter = 'middle';
      planeImage.verticalCenter = 'middle';
      planeImage.path =
        'm2,106h28l24,30h72l-44,-133h35l80,132h98c21,0 21,34 0,34l-98,0 -80,134h-35l43,-133h-71l-24,30h-28l15,-47';
      // planeImage.fill = "red";
      planeImage.fill = '#f00605';
      planeImage.strokeOpacity = 0;

      var shadowPlane = shadowLineSeries.mapLines
        .getIndex(0)
        .lineObjects.create();
      shadowPlane.position = 0;
      shadowPlane.width = 100;
      shadowPlane.height = 100;
      shadowPlane.colors = '#FFF';
      shadowPlane.scale = 1.2;

      var shadowPlaneImage = shadowPlane.createChild(am4core.Sprite);
      shadowPlaneImage.scale = 0.05;
      shadowPlaneImage.horizontalCenter = 'middle';
      shadowPlaneImage.verticalCenter = 'middle';
      shadowPlaneImage.path =
        'm2,106h28l24,30h72l-44,-133h35l80,132h98c21,0 21,34 0,34l-98,0 -80,134h-35l43,-133h-71l-24,30h-28l15,-47';
      shadowPlaneImage.fill = am4core.color('#000');
      shadowPlaneImage.strokeOpacity = 0;
      shadowPlaneImage.scale = 0.3;

      shadowPlane.adapter.add('scale', function (scale, target) {
        target.opacity = 0.6 - Math.abs(0.5 - target.position);
        return 0.5 - 0.3 * (1 - Math.abs(0.5 - target.position));
      });

      // Plane animation
      var currentLine = 0;
      var direction = 1;
      function flyPlane() {
        // Get current line to attach plane to
        plane.mapLine = lineSeries.mapLines.getIndex(currentLine);
        plane.parent = lineSeries;
        shadowPlane.mapLine = shadowLineSeries.mapLines.getIndex(currentLine);
        shadowPlane.parent = shadowLineSeries;
        shadowPlaneImage.rotation = planeImage.rotation;

        // Set up animation
        var from, to;
        var numLines = lineSeries.mapLines.length;
        if (direction == 1) {
          from = 0;
          to = 1;
          if (planeImage.rotation != 0) {
            planeImage
              .animate({ to: 0, property: 'rotation' }, 1000)
              .events.on('animationended', flyPlane);
            return;
          }
        } else {
          from = 1;
          to = 0;
          if (planeImage.rotation != 180) {
            planeImage
              .animate({ to: 180, property: 'rotation' }, 1000)
              .events.on('animationended', flyPlane);
            return;
          }
        }

        // Start the animation
        var animation = plane.animate(
          {
            from: from,
            to: to,
            property: 'position'
          },
          6000,
          am4core.ease.sinInOut
        );
        animation.events.on('animationended', flyPlane);

        shadowPlane.animate(
          {
            from: from,
            to: to,
            property: 'position'
          },
          6000,
          am4core.ease.sinInOut
        );

        // Increment line, or reverse the direction
        currentLine += direction;
        if (currentLine < 0) {
          currentLine = 0;
          direction = 1;
        } else if (currentLine + 1 > numLines) {
          currentLine = numLines - 1;
          direction = -1;
        }
      }

      setTimeout(() => {
        flyPlane();
      }, 1000);

      if(document.documentElement.clientWidth >= 768)
        animateBanner2(fixData, 6000);
      else{
        mobileAnimateBanner2(fixData, 6000);
      }
      
    });
  }, []);
  return (
    <div
      id='chartdiv'
      style={{
        width: '100%',
        height: '100%',
        background: 'url("/assets/images/en/home/banner-2/banner-2-bg.png")',
        backgroundSize: '100% 100%',
        padding: '12vh 0px',
        display: 'flex',
        // alignItems: 'center',
        position: 'relative',
        justifyContent: 'center',
      }}
    ></div>
  );
}
