import styles from './index.module.css';
import FacebookIcon from 'assets/images/en/socials/facebook-f-brand.svg';
import LinkedInIcon from 'assets/images/en/socials/linkedin-in-brand.svg';
import { FetchFooterLeft, FetchFooterRight } from 'services/en/data/home';
import LogoNapa from 'assets/images/en/home/logo_napa.svg';

import parser from 'html-react-parser';
import ImgISO27001 from 'assets/images/en/home/logo_ISO27001.png';
import ImgISO9001 from 'assets/images/en/home/logo_ISO9001.png';

const Footer = () => {
  const { loadingFL, footerLeft, errorFL } = FetchFooterLeft();
  const { loadingFR, footerRight, errorFR } = FetchFooterRight();
  if (!footerLeft || !footerRight) return <div></div>;
  if (loadingFL || errorFL || loadingFR || errorFR) return <div></div>;
  const leftOpts = [
    {
      title: footerLeft?.footer_left_title[0].en,
      childOpts: footerLeft?.footer_name_top?.map((i) => ({ name: i.en }))
    },
    {
      title: footerLeft?.footer_left_title[1].en,
      // childOpts: footerLeft?.footer_name_bottom?.map(i => ({name: i.en}))
      childOpts: [{ name: footerLeft?.footer_name_bottom?.en }]
    }
  ];
  // const leftOpts =
  const rightOpts = [
    {
      title: footerRight?.footer_right_title?.en,
      childOpts: footerRight?.footer_right_name?.map((i) => ({
        name: parser(parser(i.en))
      }))
    }
  ];


  return (
    <footer className={styles.root}>
      <div className={styles.wrapHelperLink}>
        <div className={styles.socialLink}>
          <div className={styles.imgNapa}>
            <img alt='Logo Napa' src={LogoNapa} />
          </div>
          <div className={styles.wrapSocial}>
            <a
              target='_blank'
              href='https://www.facebook.com/Napaglobal'
            // onClick={() =>
            //   (window.location.href = 'https://www.facebook.com/Napaglobal')
            // }
            >
              <img alt='Facebook Logo' src={FacebookIcon} />
            </a>
            <a
              target='_blank'
              href='https://www.linkedin.com/company/napaglobal'
            // onClick={() =>
            //   (window.location.href =
            //     'https://www.linkedin.com/company/napaglobal')
            // }
            >
              <img alt='Linkedin Logo' src={LinkedInIcon} />
            </a>
            {/* <div onClick={() => window.location.href = 'https://www.linkedin.com/company/napaglobal'}>
              <img alt='LinkedIn Logo' src={TwitterIcon} />
            </div> */}
          </div>
          <div className={styles.wrapISO}>
            <img alt='Logo Napa' src={ImgISO9001} />
            <img alt='Logo Napa' src={ImgISO27001} />
          </div>
        </div>
        <div className={styles.width20}>
          {leftOpts.map((opts, key) => (
            <div
              key={key}
              className={key < leftOpts.length - 1 ? styles.mb16 : undefined}
            >
              <h4 className={styles.title}>{opts.title}</h4>
              <ul className={styles.details}>
                {opts?.childOpts?.map((childOpt, index) => (
                  <li key={index} className={styles.child}>
                    {childOpt.name}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className={styles.width20}>
          {rightOpts &&
            rightOpts.map((opts, key) => (
              <div
                key={key}
                className={key < leftOpts.length - 1 ? styles.mb16 : undefined}
              >
                <h4 className={styles.title}>{opts.title}</h4>
                <ul className={styles.details}>
                  {opts?.childOpts?.map((childOpt, index) => (
                    <li key={index} className={styles.child}>
                      {childOpt.name}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.wrapCopyRight}>
        <span className={styles.copyRight}>
          © 2021 NAPA Global. All Rights Reserved.
        </span>
        {/* <span className={styles.terms}>Terms of use</span>
        <div className={styles.split} />
        <span className={styles.terms}>Privacy Policy</span> */}
      </div>
    </footer>
  );
};

export default Footer;
