import {
  Begin,
  OurServices,
  Statistics,
  About,
  Team,
  Offices,
  Review,
  Consultation
} from 'components/en/home';
import { OurWorksCpn } from 'components/en/ourworks';
import BackToTopButton from 'components/en/common/BackToTopButton';
import styles from './home.module.css';
import { HomePage } from '../../../query/en/general';
import { useQuery } from '@apollo/client';

const Home = () => {
  // const { loading, data, error } = useQuery(HomePage);
  // if (loading) return <></>;
  return (
    <div>
      <Begin />
      <div className={styles.root}>
        <OurServices />
        <Statistics />
        <About />
        <OurWorksCpn center isRow={true} />
        <Team />
        <Review />
        <div className={styles.wrapOffice}>
          <Offices />
        </div>

        <Consultation />
      </div>
      <BackToTopButton />
    </div>
  );
};

export default Home;
