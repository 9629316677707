// import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./style.module.css";
import BannerMap from "../../BannerMap";
// import { client } from "../../../../apolo-client";
// import { getMap } from "../../../../query/general";
const Client = (props) => {
  return (
    <div className={styles.divcovermap}>
      <BannerMap data={props.mapdata} />
    </div>
  );
};

// export async function getStaticProps() {
//   const { data } = await client.query({ query: getMap });

//   return {
//     props: {
//       data,
//     },
//   };
// }
export default Client;
