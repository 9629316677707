import Home from 'pages/en/home';
import Services from 'pages/en/services';
import Projects from 'pages/en/projects';
import OurWorks from 'pages/en/ourworks';
import DetailProjects from 'pages/en/projects/detail';

import LandingPage from 'pages/vi/landing';
import Recruitment from 'pages/vi/recruitment';

const routes = {
  EN: [
    { component: Home, path: '/', exact: true },
    { component: Services, path: '/services', exact: true },
    { component: Projects, path: '/projects', exact: true },
    { component: OurWorks, path: '/ourworks', exact: true },
    {
      component: DetailProjects,
      path: '/projects/detail/:id',
      isDynamic: true
    }
  ],
  VI: [
    { component: LandingPage, path: '/', exact: true },
    { component: Recruitment, path: '/recruit', exact: true },
    { component: Recruitment, path: '/recruit/detail/:id', isDynamic: true }
  ]
};

export default routes;
