import styles from './index.module.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImgISO9001 from "assets/images/en/home/bannerISO/ISO_9001.png";
import ImgISO27001 from "assets/images/en/home/bannerISO/ISO_27001.png";
import clsx from 'clsx';

const iso = () => {
    return (
        <div className={styles.wrapBanner}>
            <div className={styles.wrapImage}>
                <LazyLoadImage alt="ISO_9001" src={ImgISO9001} className={styles.img9001} />
                <LazyLoadImage alt="ISO_27001" src={ImgISO27001} className={styles.img27001} />
            </div>
            <div className={styles.wrapContent}>
                <div className={styles.mainTitle}>Our Achievements</div>
                <div className={styles.subTitle}>Being recognized for ISO 9001 and 27001 certification demonstrates our constant commitment to providing customers with state-of-the-art, high-quality services while continuously adhering to regulations and specifications. Putting ourselves in your shoes, we promise a high level of quality assurance at every stage of your project.</div>
            </div>

        </div>
    );
};

export default iso;
