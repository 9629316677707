// import styles from "./statistics.module.css";
// import { FetchOverviewCompany } from "services/data/home";
// import parser from "html-react-parser";

const Statistics = () => {
  // const { loadingOC, overviewCompany } = FetchOverviewCompany();
  // const parse2 = (text, placeholder) =>
  //   loadingOC ? placeholder ?? "" : parser(text?.["en"] ?? "");
  return <div></div>;
};

export default Statistics;
