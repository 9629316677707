import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from 'ScrollToTop';

import { API_CMS } from './config';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { StoreContext } from 'utils/store';
import { useContext } from 'react';
import ENApp from 'pages/en/_app';
import VIApp from 'pages/vi/_app';

const client = new ApolloClient({
  uri: API_CMS,
  cache: new InMemoryCache()
});

function App() {
  const {
    language: [languageId, setLanguageId]
  } = useContext(StoreContext);

  return (
    <>
      <ApolloProvider client={client}>
        <header>
          <meta
            name="google-site-verification"
            content="gXCsZMNC0F1mZH5V4InKpEf_Tt1SiznY423Gz0y0XBo"
          />
        </header>
        <ScrollToTop>{languageId === 0 ? <ENApp /> : <VIApp />}</ScrollToTop>
      </ApolloProvider>
    </>
  );
}

export default App;
