import React, { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const StoreContext = createContext(null);

const storeLanguageID = (languageId) => {
  localStorage.setItem('languageID', languageId);
};

function useQuery() {
  // console.log('useLocation().search', useLocation());
  return new URLSearchParams(useLocation().search);
}
const StoreProvier = ({ children }) => {
  let query = useQuery();
  let languageQuery = query.get('lang')?.toLowerCase();

  const languageQueryId = !languageQuery
    ? null
    : languageQuery == 'en'
    ? '0'
    : languageQuery == 'vi'
    ? '1'
    : null;

  const initLanguageData =
    languageQueryId || JSON.parse(localStorage.getItem('languageID'));

  const [language, setLanguage] = useState(
    (!initLanguageData || initLanguageData > 2 || initLanguageData < 0) ? 0 : parseInt(initLanguageData)
  );

  storeLanguageID(initLanguageData);

  const handleChangeLanguage = (languageId) => {
    if (languageId === 2) {
      localStorage.removeItem('languageID');
      window.location.href = 'http://napa-solutions.com/';
      return;
    }

    storeLanguageID(languageId);
    setLanguage(languageId);
    window.location.href = '/';
  };

  const store = {
    language: [language, handleChangeLanguage]
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreProvier;
